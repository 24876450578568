import {HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouterModule} from '@angular/router';
import {CarouselModule} from 'ngx-owl-carousel-o';
import {AppRoutingModule, routes} from './app-routing.module';
import {AppComponent} from './app.component';
import {LayoutsModule} from './layouts/layouts.module';
import {PagesModule} from './pages/pages.module';
import {SharedModule} from './shared/shared.module';
import { FormsModule } from '@angular/forms';

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BrowserModule.withServerTransition({appId: 'serverApp'}),
        AppRoutingModule,
        BrowserAnimationsModule,
        LayoutsModule,
        PagesModule,
        HttpClientModule,
        CarouselModule,
        FormsModule,
        // ScrollToModule,
        SharedModule,
        RouterModule.forRoot(routes, {useHash: false, anchorScrolling: 'enabled', scrollPositionRestoration: 'enabled'})

    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule {
}
