<!--feature css start-->
<section class="saas2 feature booking" id="feaure">
  <div class="container">
      <div class="row">
          <div class="col-md-10 offset-md-1">
              <div class="title">
                  <div class="main-title">
                      <h2>Я рада приветствовать Вас на моем сайте!</h2>
                  </div>
                  <div class="sub-title">
                      <p class="sub-title-para">
                          Если Вы открыли эту страницу, то, наверное, испытываете потребность в юридической помощи
                          адвоката.
                          <br>
                          При моем участии в разрешении Вашей проблемы я гарантирую профессионализм,
                          комплексность и глубину проработки задач, вежливое и доступное общение, добросовестность,
                          пунктуальность, обязательность и мобильность на весь период нашего сотрудничества. Я люблю
                          свое дело и дорожу репутацией.
                      </p>
                  </div>
              </div>
          </div>
          <div class="col-xl-8 col-md-7">
              <div class="center-text justify-content-center">
                  <div class="image-container">
                      <img src="assets/images/saas2/we-offer/img2.png" alt=""
                           class="img-fluid advance-feature-img">
                  </div>
              </div>
          </div>
          <div class="col-xl-4 col-md-5">
              <div *ngFor="let data of features">
                  <div class="center-content justify-content-start">
                      <img [src]="data.img" alt="" class="img-fluid png-ico" width="57px">
                      <div class="feature-content">
                          <h5 class="feature-head">{{data.title}}</h5>
                          <p class="feature-para">{{data.description}}</p>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</section>
<!--feature css end-->
