<!--megamenu section Start-->
<header class="loding-header custom-scroll" [ngClass]="url == '/contacts' ?'nav-abs':'dark'">
    <div class="container">
        <div class="row">
            <div class="col">
                <nav>
                    <a [routerLink]="['/']"  class="d-inline-block m-r-auto">
                        <img alt="" class="img-fluid brand-logo"
                             [src]="url == '/contacts' ?'assets/images/logo-white.png':'assets/images/logo.png'"></a>
                    <app-menu></app-menu>
                </nav>
            </div>
        </div>
    </div>
</header>
<!--megamenu section end-->
