import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LayoutsRoutingModule} from './layouts-routing.module';
import {CarouselModule} from 'ngx-owl-carousel-o';
import {SWIPER_CONFIG, SwiperConfigInterface, SwiperModule} from 'ngx-swiper-wrapper';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {SharedModule} from '../shared/shared.module'
import {CountToModule} from 'angular-count-to';
import {GalleryModule} from '@ks89/angular-modal-gallery';
import {AngularTiltModule} from 'angular-tilt';
import 'hammerjs';
import 'mousetrap';
import {ScrollToModule} from 'ng2-scroll-to-el';
import {MasonryGalleryModule} from 'ngx-masonry-gallery';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgxPayPalModule} from 'ngx-paypal';
import {Ng5SliderModule} from 'ng5-slider';
//Enterprice Layouts
import {NgxMasonryModule} from 'ngx-masonry';
import {MainComponent} from "./main/main.component";
import {MainBrandComponent} from "./main/main-brand/main-brand.component";
import {MainBusinessComponent} from "./main/main-business/main-business.component";
import {MainFeatureComponent} from "./main/main-feature/main-feature.component";
import {MainFooterComponent} from "./main/main-footer/main-footer.component";
import {MainHeaderComponent} from "./main/main-header/main-header.component";
import {MainNavComponent} from "./main/main-nav/main-nav.component";
import {MainPricingComponent} from "./main/main-pricing/main-pricing.component";
import {MainScreenshotsComponent} from "./main/main-screenshots/main-screenshots.component";
import {MainTestimonialComponent} from "./main/main-testimonial/main-testimonial.component";
import {MainServicesComponent} from "./main/main-services/main-services.component";

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {};

@NgModule({
    declarations: [
        MainComponent,
        MainBrandComponent, MainBusinessComponent, MainFeatureComponent, MainFooterComponent, MainHeaderComponent,
        MainNavComponent, MainPricingComponent, MainScreenshotsComponent, MainTestimonialComponent, MainServicesComponent,


    ],

    imports: [
        CommonModule,
        LayoutsRoutingModule,
        SwiperModule,
        CarouselModule,
        NgbModule,
        GalleryModule.forRoot(),
        SharedModule,
        CountToModule,
        AngularTiltModule,
        ScrollToModule.forRoot(),
        MasonryGalleryModule,
        FormsModule,
        ReactiveFormsModule,
        NgxPayPalModule,
        Ng5SliderModule,
        NgxMasonryModule
    ],

    exports: [],

    providers: [
        {
            provide: SWIPER_CONFIG,
            useValue: DEFAULT_SWIPER_CONFIG
        }
    ]
})

export class LayoutsModule {
}
