<!--footer start-->
<footer class="saas2 footer2" id="contact" [ngStyle]="{'background-image': 'url(assets/images/saas2/footer.png)', 'background-repeat': 'no-repeat', 'background-position': 'top'}">
    <div class="container footer-padding">
        <div class="row">
            <div class="col-md-12 col-sm-12">
                <div class="logo-sec">
                    <div class="footer-title mobile-title p-t-0">
                        <h3 class="text-white">Контакты</h3>
                    </div>
                    <div class="footer-contant">
                        <img src="assets/images/logo-white.png" alt="" class="img-fluid footer-logo" style="height:35px">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="footer-para">
                                    <h6 class="text-white para-address">член Адвокатской палаты города Москвы </h6>
                                    <h6 class="text-white para-address">рег.№ 77/15808 в реестре адвокатов города Москвы</h6>
                                </div>
                            </div>
                            <div class="col-md-6">

                                <div class="footer-para">
                                    <h6 class="text-white para-address"><i class="fa fa-phone"></i> 8 902 326 23 92</h6>
                                    <h6 class="text-white para-address"><i class="fa fa-home"></i> 125167, г. Москва, ул. Викторенко, д. 11, этаж 1, помещение 23</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

<!--            <div class="col-lg-4 col-md-6 col-sm-12">-->
<!--                <div class="footer-title mobile-title">-->
<!--                    <h3 class="text-white">Меню</h3>-->
<!--                </div>-->
<!--                <div class="footer-contant">-->
<!--                    <h5 class="footer-headings">Меню</h5>-->
<!--                    <div>-->
<!--                        <ul class="footer-lists">-->
<!--                            <li class="">-->
<!--                                <a href="#">Главная</a>-->
<!--                            </li>-->
<!--                            <li class="">-->
<!--                                <a href="#">Обо мне</a>-->
<!--                            </li>-->
<!--                            <li class="">-->
<!--                                <a href="#">Услуги</a>-->
<!--                            </li>-->
<!--                            <li class="">-->
<!--                                <a href="#">Отзывы</a>-->
<!--                            </li>-->
<!--                            <li class="">-->
<!--                                <a href="#">Контакты</a>-->
<!--                            </li>-->
<!--                        </ul>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--            <div class="col-lg-3 col-md-6 col-sm-12">-->
<!--                <div class="footer-title mobile-title">-->
<!--                    <h3 class="text-white">Subscribe our newsletter</h3>-->
<!--                </div>-->
<!--                <div class="footer-contant">-->
<!--                    <h5 class="footer-headings">Subscribe our newsletter</h5>-->
<!--                    <div class="form-group">-->
<!--                        <input type="text" class="form-control" id="" placeholder="email address" />-->
<!--                        <a href=""><i class="fa fa-paper-plane" aria-hidden="true"></i></a>-->
<!--                    </div>-->
<!--                    <div>-->
<!--                        <h4 class="text-white unice-text">unice</h4>-->
<!--                        <h6 class="text-white unice-copyright-text">© 2018_unice</h6>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--            <div class="col-lg-3 col-md-6 col-sm-12">-->
<!--                <div class="footer-title mobile-title">-->
<!--                    <h3 class="text-white">Контакты</h3>-->
<!--                </div>-->
<!--                <div class="footer-contant">-->
<!--                    <h5 class="footer-headings">Контакты</h5>-->
<!--                    <div class="form-group">-->
<!--                        <h4 class="text-white">Телефон:</h4>-->
<!--                        <h6 class="text-white unice-copyright-text">8 902 326 23 92</h6>-->
<!--                    </div>-->
<!--                    <div>-->
<!--                        <h4 class="text-white">Адрес:</h4>-->
<!--                        <h6 class="text-white unice-copyright-text">424002 Республика Марий Эл,</h6>-->
<!--                        <h6 class="text-white unice-copyright-text">г. Йошкар-Ола, б. Победы, д. 13</h6>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
        </div>
    </div>
</footer>
<app-tap-to-top></app-tap-to-top>
