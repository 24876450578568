import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ServicesComponent} from './services/services.component';
import { AboutComponent } from './about/about.component';
import {ContactsComponent} from "./contacts/contacts.component";
import {RecallComponent} from "./recall/recall.component";

const routes: Routes = [
  {
    path: '404',
    component: PageNotFoundComponent
  },
  {
    path: 'services',
    component: ServicesComponent,
    data: {
      title: "Услуги",
      breadcrumb: "Услуги"
    }
  },
  {
    path: 'recall',
    component: RecallComponent,
    data: {
      title: "Отзывы",
      breadcrumb: ""
    }
  },
  {
    path: 'about',
    component: AboutComponent,
    data: {
      title: "Обо мне",
      breadcrumb: "Обо мне"
    }
  },
  {
    path: 'contacts',
    component: ContactsComponent,
    data: {
      title: "Контакты",
      breadcrumb: "Контакты"
    }
  },
  {path: '**', redirectTo: '/404'}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PagesRoutingModule { }
