import {Component, OnInit} from '@angular/core';
import {HttpService} from './http.service';
import {User} from './user';
import {RecaptchaModule, RecaptchaFormsModule, RECAPTCHA_V3_SITE_KEY} from "ng-recaptcha";


@Component({
    selector: 'app-contacts',
    templateUrl: './contacts.component.html',
    styleUrls: ['./contacts.component.scss'],
    providers: [{ provide: RECAPTCHA_V3_SITE_KEY, useValue: "6LeSOtgbAAAAAJQpruqq9_FQBVBe5DqitNB3Wtw2" }, HttpService]
})
export class ContactsComponent implements OnInit {
    user: User = new User("", "", "", "", "");
    receivedUser: User | undefined; // полученный пользователь
    done: boolean = false;
    ErrorSend: boolean = false;
    ErrorMessage: string = 's';

    constructor(private httpService: HttpService) {
    }

    ngOnInit() {
    }

    onSubmit(user: User) {
        this.ErrorSend = false;
        this.ErrorMessage = '';

        this.httpService.postData(user)
            .subscribe(
                (data: any) => {
                    if (!data.hasOwnProperty('error')) this.done = true;
                    else {
                        this.ErrorSend = true;
                        this.ErrorMessage = data.error;
                        console.log(data);
                    }
                },
                error => console.log(error)
            );
    }

}
