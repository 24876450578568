<div class="agency">
	<app-header></app-header>
	<app-breadcrumb></app-breadcrumb>

	<section class="gym format">
		<div class="container">
			<div class="row">
				<div class="col-md-8 offset-md-2">
					<div class="center-text">
						<div class="text-center">
							<div class="format-head-text">
								<h3 class="about-font-header gradient-text">Об адвокате</h3>
							</div>
							<div class="format-sub-text mb-0">
								<p class="p-light about-para">
									С мая 2007 года являлась адвокатом Адвокатской палаты Республики Марий Эл.
									Форма адвокатского образования – адвокатский кабинет.
								</p><p class="p-light about-para">
									В ноябре 2009 года прошла обучение на Высших курсах повышения квалификации адвокатов Российской Федерации,
									в целях повышения квалификации принимала участие в вебинарах, семинарах, организованных Адвокатской палатой Республики Марий Эл,
									Федеральной палатой адвокатов РФ и Центром правовых исследований, адвокатуры и дополнительного профессионального образования ФПА РФ.
							</p><p class="p-light about-para">
								03 октября 2017 года награждена Благодарственным письмом Президента Федеральной палаты адвокатов Российской Федерации за
								высокое профессиональное мастерство при защите прав, свобод и законных интересов доверителей, активную деятельность по
								укреплению единства адвокатуры.
							</p><p class="p-light about-para">
									20 июля 2017 года  награждена Почетной грамотой Адвокатской палаты Республики Марий Эл за высокое
									профессиональное мастерство при защите прав, свобод и законных интересов доверителей,
									продолжительную и безупречную работу, большой личный вклад в оказание высококвалифицированной юридической
									помощи населению Республики марий Эл и в связи с юбилеем.
							</p><p class="p-light about-para">
									09 февраля 2019 года награждена Дипломом профессионального отличия Адвокатской палаты
									Республики Марий Эл "За оправдательный приговор".
								</p>
								<p class="p-light about-para">Решением Совета Адвокатской палаты города Москвы 25 февраля 2021 года
									принята в члены Адвокатской палаты города Москвы. Форма адвокатского
									образования – адвокатский кабинет.</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>

	<section class="resume counter bg-pink">
		<div class="container">
			<div class="row">
				<div class="col-xl-3 col-6 counter-container">
					<div class="counters">
						<img src="assets/images/resume/icon/1.png" alt="" class="img-fluid counter-img">
						<div class="counter-text">
							<h3 class="count-text counts">36 лет</h3>
							<h5 class="count-desc">Общий стаж</h5>
						</div>
					</div>
				</div>
				<div class="col-xl-3 col-6 counter-container">
					<div class="counters">
						<img src="assets/images/resume/icon/2.png" alt="" class="img-fluid counter-img">
						<div class="counter-text">
							<h3 class="count-text counts">22 года</h3>
							<h5 class="count-desc">Юридический стаж</h5>
						</div>
					</div>
				</div>
				<div class="col-xl-3 col-6 counter-container">
					<div class="counters">
						<img src="assets/images/resume/icon/3.png" alt="" class="img-fluid counter-img">
						<div class="counter-text">
							<h3 class="count-text counts">14 лет</h3>
							<h5 class="count-desc">Стаж адвоката</h5>
						</div>
					</div>
				</div>
				<div class="col-xl-3 col-6 counter-container">
					<div class="counters">
						<img src="assets/images/resume/icon/4.png" alt="" class="img-fluid counter-img">
						<div class="counter-text">
							<h3 class="count-text counts">1503</h3>
							<h5 class="count-desc">Выиграно дел</h5>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>

	<section class="event testimonial set-relative bg about-page">
		<div class="container">
			<div class="row">
				<div class="col-md-10 offset-md-1 margin-b">
					<div class="p-t-30">
						<div class="col-lg-10 offset-lg-1 col-12">
							<div class="row">
								<div class="col-xl-3 col-sm-4">
									<div class="testimonial-msg set-relative">
										<img src="assets/images/icon-law/png/LegalDocument.png" alt=""
											 class="img-fluid avtar-img">
									</div>
								</div>
								<div class="col-xl-9 col-sm-8">
									<div class="quotes set-relative m-b-30">
										<div class="quote-text">
											<h5>В 1992 году окончила Казанский государственный университет им. В.И.
												Ульянова-Ленина по
												специальности «Журналистика». Работала в редакции районной газеты более
												10 лет, последние 5
												лет – в должности редактора. В 1994 году Союзом журналистов Республики
												Марий Эл награждена
												дипломом за победу в конкурсе на лучшую журналистскую работу.</h5>
										</div>
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col-xl-9 col-sm-8">
									<div class="quotes set-relative m-b-30">
										<div class="quote-text">
											<h5>Несколько лет работала на выборных должностях – депутатом поселкового
												Совета, вторым
												секретарем райкома ВЛКСМ, председателем районного Совета Союза молодежи
												Республики
												Марий Эл.</h5>
										</div>
									</div>
								</div>
								<div class="col-xl-3 col-sm-4">
									<div class="testimonial-msg set-relative">
										<img src="assets/images/icon-law/png/Court.png" alt=""
											 class="img-fluid avtar-img">
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col-xl-3 col-sm-4">
									<div class="testimonial-msg set-relative">
										<img src="assets/images/icon-law/png/Certificate.png" alt=""
											 class="img-fluid avtar-img">
									</div>
								</div>
								<div class="col-xl-9 col-sm-8">
									<div class="quotes set-relative m-b-30">
										<div class="quote-text">
											<h5>В 2001 году окончила Марийский государственный университет по
												специальности
												«Юриспруденция». В течение 6 лет работала в Йошкар-Олинском городском
												суде Республики
												Марий Эл на государственной должности начальника отдела. За
												добросовестное исполнение
												должностных обязанностей, профессиональное мастерство приказом
												председателя суда
												неоднократно объявлялись благодарности. Государственное Собрание
												Республики Марий Эл в
												декабре 2005 года наградило благодарственным письмом за высокий
												профессионализм,
												преданность избранной профессии, уроки наставничества. В 2004 и 2005
												годах проходила
												краткосрочное обучение в Российской академии правосудия.</h5>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
	<app-footer></app-footer>
</div>
