<!--business css start-->
<section class="saas2 bg-gradient quick-sol" [ngStyle]="{'background-image': 'url(assets/images/saas2/background/quick-sol.png)'}">
  <div class="container set-center-div">
      <div class="row">
          <div class="col-lg-6">
              <div class="offers-container">
                  <h3 class="text-white offer-heading-text">Специализация</h3>
                  <div class="bottom-border">
                  </div>
                  <div class="offers-content" *ngFor="let data of business">
                      <div class="d-flex">
                          <i class="fa fa-check-circle" aria-hidden="true"></i>
                          <div>
                              <h4 class="text-white">{{data.title}}</h4>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div class="center-text side-img">
              <img alt="" src="assets/images/saas2/we-offer/img.png" class="img-fluid" data-tilt data-tilt-max="3"
                   data-tilt-speed="400" data-tilt-perspective="500">
          </div>
      </div>
  </div>
</section>
