import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {MainComponent} from "./main/main.component";

const routes: Routes = [
   {
    path: 'main',
    component: MainComponent,
    data: {
      title: "Адвокат Целищева Татьяна Евгеньевна",
      content: "Адвокат Целищева Татьяна Евгеньевна"
    }
  }

]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LayoutsRoutingModule { }
