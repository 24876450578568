<!--header css start-->
<section class="saas2 header" id="home">
  <div class="saas2-content ">
      <div class="bg saas2-bg"  [ngStyle]="{'background-image': 'url(assets/images/saas2/slider.png)'}">
          <div class="container">
              <div class="row">
                  <div class="col-lg-6">
                      <div class="center-text">
                          <div class="">
                              <div class="header-text">
                                  <h1>Юридическая помощь</h1>
                              </div>
                              <div class="header-sub-text"></div>
                              <div class="header-sub-text">
                                  <h4 class="saas2-sub-text">Адвокат</h4>
                                  <h2 class="sub-para text-white">Целищева Татьяна Евгеньевна</h2>
                              </div>
                              <div class="header-sub-text"></div>
                              <a class="btn btn-default primary-btn" routerLink="/contacts">Записаться на прием</a>
                              <a class="btn btn-default primary-btn m-l-24" href="tel:+79023262392"><i class="fa fa-phone"></i> 8 902 326 2392</a>
                          </div>
                      </div>
                  </div>
                  <div class="center-text set-abs">
                      <img alt="" src="assets/images/main.svg" class="img-fluid" style="height: 400px">
                  </div>
              </div>
          </div>

          <img alt="" src="assets/images/saas1/background2.png"
               class="img-fluid set-abs background-animate">
          <img alt="" src="assets/images/saas2/header-icon/3.png"
               class="img-fluid set-abs img1 move-up-down">
          <img alt="" src="assets/images/saas2/header-icon/2.png"
               class="img-fluid set-abs img2 move-right-left">
          <img alt="" src="assets/images/saas2/header-icon/4.png"
               class="img-fluid set-abs img3 move-up-down">
          <img alt="" src="assets/images/saas2/header-icon/5.png"
               class="img-fluid set-abs img4 move-up-down">
          <img alt="" src="assets/images/saas2/header-icon/7.png"
               class="img-fluid set-abs img5 move-right-left">
          <img alt="" src="assets/images/saas2/header-icon/9.png"
               class="img-fluid set-abs img6 move-up-down">
          <img alt="" src="assets/images/saas2/header-icon/6.png"
               class="img-fluid set-abs img7 move-up-down">
          <div class="set-abs round move-right-left">
              <img alt="" src="assets/images/saas2/header-icon/10.png" class="img-fluid img8">
          </div>
          <div class="center-content set-abs bottom-content">
              <div class="bottom">
                  <a class="down">
                      <img alt="" src="assets/images/saas2/header-icon/down.png" [scrollTo]="'#feaure'"  [scrollDuration]="1000" class="img-fluid">
                  </a>
                  <div>
                  </div>
              </div>
          </div>
      </div>
  </div>
  <div>
  </div>
</section>
<!--header css end-->
