import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CarouselModule} from 'ngx-owl-carousel-o';

import {PagesRoutingModule} from './pages-routing.module';
import {SharedModule} from '../shared/shared.module'
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

import {PagesComponent} from './pages.component';
import {PageNotFoundComponent} from './page-not-found/page-not-found.component';
import {AboutComponent} from './about/about.component';
import {ServicesComponent} from "./services/services.component";
import {ContactsComponent} from "./contacts/contacts.component";
import {RecallComponent} from "./recall/recall.component";
import { FormsModule } from '@angular/forms';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from "ng-recaptcha";

@NgModule({
    declarations: [PagesComponent, PageNotFoundComponent, RecallComponent,
        AboutComponent, ServicesComponent, ContactsComponent],
    imports: [CommonModule, PagesRoutingModule, SharedModule, CarouselModule, RecaptchaV3Module, FormsModule, NgbModule],
    providers: [{ provide: RECAPTCHA_V3_SITE_KEY, useValue: "6LeSOtgbAAAAAJQpruqq9_FQBVBe5DqitNB3Wtw2" }],
})
export class PagesModule {
}
