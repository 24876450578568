import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

// Menu
export interface Menu {
    path?: string;
    title?: string;
    type?: string;
    icon?: string;
    badgeType?: string;
    badgeValue?: string;
    active?: boolean;
    megaMenu?: boolean;
    megaMenuType?: string; // small, medium, large
    bookmark?: boolean;
    children?: Menu[];
}

@Injectable({
    providedIn: 'root'
})
export class NavService {

    constructor() {
    }

    MENUITEMS: Menu[] = [
        {title: 'Главная', type: 'link', path: '/'},
        {title: 'Обо мне', type: 'link', path: '/about'},
        {title: 'Услуги', type: 'link', path: '/services'},
        {title: 'Отзывы', type: 'link', path: '/recall'},
        {title: 'Контакты', type: 'link', path: '/contacts'},
    ]

    items = new BehaviorSubject<Menu[]>(this.MENUITEMS);

}
