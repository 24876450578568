import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-main-business',
  templateUrl: './main-business.component.html',
  styleUrls: ['./main-business.component.scss']
})
export class MainBusinessComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  business = [
    {title:'Жилищные споры'},
    {title:'Семейные споры'},
    {title:'Земельные споры'},
    {title:'Трудовые споры'},
    {title:'Дела особого производства'},
    {title:'Наследственные дела'},
    {title:'Споры по возмещению вреда,'},
    {title:'Пенсионные споры'},
    {title:'Споры из договоров страхования'},
    {title:'Споры по защите прав потребителей'},
    {title:'Споры по налогообложению'},
    {title:'Споры по исполнению обязательств по договорам'},
    {title:'Истребование имущества из чужого незаконного владения'},
  ]
}
