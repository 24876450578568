<!--footer start-->
<footer class="resume copyright copyright-bg inner-pages-footer">
    <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="link link-horizontal  text-center mb-3">
                        <img alt="" class="img-fluid" src="assets/images/logo.png">
                    </div>
                </div>
                <div class="col-12">
                    <div class="link link-horizontal">
                        <ul class="justify-content-center">
                            <li><a class="copyright-text" routerLink="/">Главная</a></li>
                            <li><a class="copyright-text" routerLink="/about">Обо мне</a></li>
                            <li><a class="copyright-text" routerLink="/services">Услуги</a></li>
                            <li><a class="copyright-text" routerLink="/recall">Отзывы</a></li>
                            <li><a class="copyright-text" routerLink="/contacts">Контакты</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
</footer>
<div class="agency copyright inner-page">
    <div class="container">
            <div class="row">
                <div class="col-sm-6">
                </div>
                <div class="col-sm-6">
                </div>
            </div>
        </div>
</div>
<!--copyright start-->    
<app-tap-to-top></app-tap-to-top>

