<div class="agency">
	<app-header style=""></app-header>
	<app-breadcrumb></app-breadcrumb>
	<section class="wishlist-section section-b-space">
		<div class="container row">
			<div class="col-md-6 empty-cart-cls p-b-50">
				<p class="p-light about-para">
					<b>29.07.2021. </b>Когда на кону стоит свобода, угроза судимости, репутационные и
					материальные потери, правильный выбор адвоката становится решением, способным
					повлиять на дальнейший ход жизни.<br>
					После звонка следователя, который вызвал меня на допрос, я был очень напуган.
					Мы с отцом стали обзванивать родных и знакомых, чтобы они порекомендовали нам
					хорошего адвоката. Нам назвали фамилии нескольких хороших адвокатов, но после
					общения с ними мы решили заключить договор с Татьяной Евгеньевной, поскольку
					именно она вызвала у нас большее доверие. В последующем оказалось, что мы в выборе
					адвоката не ошиблись. Я доволен исходом дела, поскольку суд не назначил мне реальный
					срок лишения свободы с учетом избранной мной позиции по уголовному делу, так как
					Татьяна Евгеньевна правильно сориентировала меня. Мне известно, что по другому
					аналогичному уголовному делу, обвиняемый, избравший иную позицию, был заключен
					под стражу и в итоге суд назначил ему реальный срок лишения свободы.<br>
					Желаю Татьяне Евгеньевне успехов в нелегком деле, профессиональных побед. <br>
				</p>
				<p class="pull-right"><b>Дмитрий Н.</b></p>
			</div>
			<div class="col-md-6 empty-cart-cls p-b-50">
				<p class="p-light about-para">
					<b>29.07.2021. </b>28.07.2021. «От тюрьмы и от сумы не зарекайся», – так гласит народная мудрость.
					Кто из нас сможет сказать, что с ним произойдет завтра? Даже сегодня можно выйти на
					улицу и столкнуться с такими проблемами, разрешить которые поможет только хороший
					юрист. Именно поэтому очень важно знать, что в любой ситуации, связанной с силовыми
					структурами и другими контролирующими органами, адвокат - ваш лучший друг и
					помощник. Возбуждение уголовного дела может грозить каждому человеку, даже тому,
					кто не замышлял ничего дурного, а просто оказался не в то время и не в том месте. В
					настоящее время много юридических компаний, юристов, оказывающих услуги
					населению. В круговороте ежедневных дел и суеты мы их просто не замечаем, особенно
					если у нас все гладко. Но как только что-то пошло не так, мы начинаем метаться из
					стороны в сторону, не зная, что предпринять и к кому обратиться. Очень важно не впадать
					в панику и тщательно выбрать адвоката, который вам поможет, что я и сделала в 2017
					году.<br>
					Случилось так, что в отношении меня дважды возбуждали уголовные дела и
					обвиняли в совершении преступлений, предусмотренных ч. 1 ст. 128.1 УК РФ («Клевета»)
					и ч. 3 ст. 160 УК РФ («Присвоение денежных средств в крупном размере»). По
					рекомендации знакомой я обратилась за помощью к Татьяне Евгеньевне, которая
					оказалась настоящим профессионалом. В первой ситуации в отношении меня был вынесен
					оправдательный приговор, во второй ситуации уголовное дело было прекращено. За такой
					положительный исход я ей очень благодарна. Татьяна Евгеньевна также помогла мне
					грамотно разрешить споры по нескольким гражданским делам.<br>
					С 2016 года всем своим знакомым, родственникам рекомендую обращаться за
					юридической помощью к Татьяне Евгеньевне, как к адвокату – профессионалу своего
					дела.<br>
				</p>
				<p class="pull-right"><b>Нина Михайловна П.</b></p>
			</div>

			<div class="col-md-6 empty-cart-cls p-b-50">
				<p class="p-light about-para">
					<b>26.07.2021. </b> Татьяна Евгеньевна предоставила мне услуги в нескольких отраслях права, включая
					уголовное, административное, семейное, гражданское, жилищное. В работе она нацелена прежде всего на
					достижение положительного результата и делает для этого все возможное. В ходе общения она не вводила
					меня в заблуждение: все возможные перспективы исхода конкретного дела были ею проанализированы и
					четко обозначены уже в ходе первой консультации. <br>
					Каждый раз, обращаясь к Татьяне Евгеньевне, я была уверена, что к моему делу она отнесется с
					максимальным вниманием. Благодаря ей, в судебном порядке был отменен договор дарения квартиры и в
					результате жилое помещение вернулось в мою собственность, другие гражданские споры также разрешились
					в мою пользу. <br>
					Желаю Вам, Татьяна Евгеньевна, новых успехов в Вашем благородном труде!
				</p>
				<p class="pull-right"><b>Екатерина Алексеевна А.</b></p>
			</div>


			<div class="col-md-6 empty-cart-cls p-b-50">
				<p class="p-light about-para">
					<b>23.07.2021. </b> Выражаю огромную благодарность Татьяне Евгеньевне, она профессионал своего дела.
					Неоднократно обращалась к ней за помощью в области семейного, земельного, гражданского, а также
					трудового права. Адвокат с большой буквы, общается всегда спокойным голосом, объясняя позицию честно
					и без прикрас, отмечая возможные риски, также очень грамотно и четко поясняет весь алгоритм, который
					мне придется пройти, опираясь на всю предоставленную мной информацию. <br>
					Я уже посоветовала ее своим друзьям и знакомым. Контактные данные о таком специалисте должны быть у
					каждого в записной книжке. <br>
					Больших успехов Вам в работе, Татьяна Евгеньевна!
				</p>
				<p class="pull-right"><b>Ольга Викторовна В. <br> руководитель кадровой службы <br>коммерческой организации
				</b></p>
			</div>


			<div class="col-md-6 empty-cart-cls p-b-50">
				<p class="p-light about-para">
					<b>19.07.2021. </b> Мы обратились к Татьяне Евгеньевне по вопросу подачи искового заявления о
					признании членом семьи нанимателя, признании права пользования жилым помещением на условиях договора
					социального найма, признании права собственности на жилое помещение в порядке приватизации. <br>
					Благодаря высокой квалификации и профессионализму Татьяны Евгеньевны, суд удовлетворил все наши
					требования в нашей непростой ситуации. <br>
					От всего сердца хотим сказать Вам БОЛЬШОЕ СПАСИБО за оказанную помощь! С Вами очень приятно общаться
					и работать.
				</p>
				<p class="pull-right"><b>Семья В-х.</b></p>
			</div>


			<div class="col-md-6 empty-cart-cls p-b-50">
				<p class="p-light about-para">
					<b>06.07.2021. </b> Выражаю благодарность адвокату Целищевой Татьяне Евгеньевне за решение вопросов
					наследства в судебном порядке, продажи доли дома и земельного участка. Спасибо ей огромное за
					профессионализм и человеческое отношение. Мы очень благодарны ей, желаем ей благополучия и
					процветания!
				</p>
				<p class="pull-right"><b>Людмила Яковлевна Н.</b></p>
			</div>


			<div class="col-md-6 empty-cart-cls p-b-50">
				<p class="p-light about-para">
					<b>10.06.2021. </b> Сотрудничаем с адвокатом Целищевой Т.Е. с апреля 2017 года.
					С 2017 года адвокат вела несколько судебных дел нашей компании по искам к ответчикам-контрагентам: о
					взыскании задолженности по заключенным договорам, финансовых санкций, судебных расходов в
					арбитражных судах первой, апелляционной, кассационной инстанций. Общая цена всех предъявленных исков
					к ответчикам – около 35 000 000 руб. Результат дел: суды удовлетворили наши требования в полном
					объеме. <br>
					В 2018 году наша компания, являясь собственником транспортного средства, была признана виновной в
					совершении административного правонарушения, предусмотренного ст. 8.25 Кодекса города Москвы об
					административных правонарушениях, и ей назначено наказание в виде административного штрафа в размере
					300 000 рублей (работник компании припарковался на служебном автомобиле на газоне). <br>
					Татьяна Евгеньевна помогла нам «отвоевать» эти 300 000 руб., хотя судебная практика по подобным
					делам сложилась далеко не в нашу пользу. При этом в ходе борьбы с бюрократической машиной нам
					пришлось пройти огонь, воду и медные трубы. Целый год Татьяна Евгеньевна отстаивала наши интересы по
					данному делу: готовила жалобу Начальнику Московской административной дорожной инспекции на
					постановление по делу об административном правонарушении; жалобу в районный суд г. Москвы на
					постановление по делу об административном, решение по жалобе на постановление по делу об
					административном правонарушении; жалобу Председателю районного суда г. Москвы на действия судьи
					районного суда г. Москвы; жалобу Председателю Московского городского суда на незаконные действия
					судьи районного суда г. Москвы; жалобу в Квалификационную коллегию судей г. Москвы на незаконные
					действия судьи районного суда г. Москвы; жалобу в Федеральную службу судебных приставов России на
					незаконные действия должностных лиц Межрайонного отдела судебных приставов по взысканию
					административных штрафов № 4 города Москва Управления Федеральной службы судебных приставов России
					по Москве. <br>
					К счастью, все решилось в нашу пользу! <br>
					В настоящий момент Татьяна Евгеньевна представляет интересы компании по делу о банкротстве нашего
					контрагента, рассматриваемому Арбитражным судом. <br>
					Татьяна Евгеньевна очень хороший, добросовестный адвокат, знающий своё дело! Результаты работы во
					время представления интересов компании в судах, правоохранительных органах, иных государственных
					инстанциях говорят о разностороннем опыте адвоката и способности вести трудные дела. <br>
					Спасибо за поддержку и профессионализм. Искренне благодарны за то, что Вы всегда отстаиваете наши
					интересы. <br>
					Желаем Вам и в дальнейшем успехов в Вашей непростой работе!!!
				</p>
				<p class="pull-right"><b>Дмитрий Геннадьевич П.<br>
					генеральный директор
					коммерческой организации
				</b></p>
			</div>


			<div class="col-md-6 empty-cart-cls p-b-50">
				<p class="p-light about-para">
					<b>05.05.2021. </b> Я обратилась к Татьяне Евгеньевне по вопросу подачи искового заявления о
					признании права пользования жилым помещением на условиях социального найма. С Татьяной Евгеньевной
					приятно было работать, она знает свое дело. Я очень благодарна ей за положительный исход дела,
					огромное ей спасибо!
				</p>
				<p class="pull-right"><b>Валентина Николаевна О.</b></p>
			</div>


			<div class="col-md-6 empty-cart-cls p-b-50">
				<p class="p-light about-para">
					<b>25.06.2019. </b> Выражаем огромную благодарность адвокату Целищевой Татьяне Евгеньевне за
					оказанную профессиональную юридическую помощь! <br>
					В 2017 году наша организация попала в очень неприятную ситуацию. На протяжении 2016-2017 гг. искали
					в г. Москве коммерческое помещение для покупки и размещения там нашего бизнеса. Наконец, в октябре
					2017 года заключили с застройщиком договор купли-продажи будущей недвижимости, согласно которому
					помещение нам должны были передать в собственность в январе 2018 года. При заключении договора
					внесли 100% предоплату за помещение. Однако, продавец не исполнял своих обязательств по передаче
					права собственности. Мы рисковали остаться без помещения и без оплаченных денежных средств. <br>
					В срочном порядке начали искать профильного в вопросах недвижимости юриста для подачи иска в суд. По
					рекомендации нашего делового партнера обратились к Татьяне Евгеньевне. <br>
					Татьяна Евгеньевна очень внимательно отнеслась к нашей сложной ситуации, досконально изучила
					огромный ворох документации, провела непростые переговоры с продавцом, подготовила претензию в адрес
					продавца и очень оперативно и грамотно подготовила исковое заявление о признании права собственности
					на нежилое помещение. Иск был очень непростой, судебная практика – неоднозначной. Суд первой
					инстанции удовлетворил наши требования к продавцу в полном объеме: признал право собственности на
					недвижимое имущество, взыскал все судебные издержки с ответчика. <br>
					Задача минимум была выполнена! Но в результате несвоевременной передачи права собственности на
					помещение наша компания понесла существенные убытки. Татьяна Евгеньевна нам порекомендовала также
					подать иск о взыскании с ответчика понесенных убытков. И этот иск также был удовлетворён судом в
					полном объеме. С помощью адвоката мы оперативно получили взысканные убытки через службу судебных
					приставов. <br>
					Спасибо Татьяне Евгеньевне за профессиональный подход к делу, за положительный и быстрый результат в
					нашей запутанной ситуации. Искренне благодарны за то, что Вы отстояли наши интересы. Желаем Вам
					карьерного роста и больших успехов!
				</p>
				<p class="pull-right"><b>Антон Владимирович В. <br>
					генеральный директор коммерческой организации
				</b></p>
			</div>


			<div class="col-md-6 empty-cart-cls p-b-50">
				<p class="p-light about-para">
					<b>31.05.2018. </b> Мы обратились к Татьяне Евгеньевне по вопросу взыскания задолженности. В ходе
					данного разбирательства у нас появилось еще несколько спорных моментов, которые невозможно было
					решить без грамотного юриста по гражданским делам. Перед началом нашего долгосрочного (более года)
					сотрудничества Татьяна Евгеньевна проводила очень подробные и качественные консультации, после
					которых у нас не оставалось сомнений в ее профессионализме. Она в полном объеме оказала нам все
					необходимые услуги, начиная от составления искового заявления до получения исполнительного листа. В
					процессе работы Татьяна Евгеньевна показала себя очень надежной, четкой, умеющей слушать и слышать
					своего клиента, пунктуальной, компетентной, корректной, отлично знающей свое дело. Она не только
					добросовестно и профессионально выполняла свои обязательства по договору, но и всегда
					консультировала нас по сопутствующим вопросам, касающимся деятельности нашей организации. <br>
					Огромное Вам спасибо за Вашу порядочность, профессиональное мастерство и отзывчивость, работать с
					Вами приятно!
				</p>
				<p class="pull-right"><b>Игорь Алексеевич С. <br> Председатель СНТ </b></p>
			</div>

		</div>
	</section>
	<app-footer></app-footer>
</div>
