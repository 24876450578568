import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-main-feature',
  templateUrl: './main-feature.component.html',
  styleUrls: ['./main-feature.component.scss']
})
export class MainFeatureComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

features = [
  {
    img:'assets/images/icon-law/png/Handshake.png',
    title: 'Услуги для граждан',
    description: 'От консультирования до полного сопровождения Вашего дела.'
  },
  {
    img:'assets/images/icon-law/png/LawBook.png',
    title: 'Административные дела',
    description: 'Представление в административных органах и суде.'
  },
  {
    img:'assets/images/icon-law/png/JudgeOrder.png',
    title: 'Гражданские дела',
    description: 'Составление исковых заявлений и участие в производстве.'
  },
  {
    img:'assets/images/icon-law/png/Fingerprint.png',
    title: 'Уголовные дела',
    description: 'Юридическая помощь на всех этапах уголовного дела.'
  },
  {
    img:'assets/images/icon-law/png/Building.png',
    title: 'Арбитражные дела',
    description: 'Защита Ваших интересов при рассмотрении арбитражных дел.'
  }
]
}
