<div class="agency">
    <app-header></app-header>
<!--    <app-breadcrumb></app-breadcrumb>-->
    <section class="event contact set-relative bg bg-img bg-about p-b-0 event-gradient" id="contact" >
        <div class="container p-b-100">
            <div class="row">
                <div class="col-md-10 offset-md-1">
                    <div class="title title3">
                        <div class="main-title">
                            <h2 class=" text-white">Контакты</h2>
                        </div>
                        <div class="sub-title">
                            <P class="text-white">
                                Адрес: 125167, г. Москва, ул. Викторенко, д. 11, этаж 1, помещение 23 (адвокатский кабинет)
                                <br>Тел: 8 902 326 23 92
                                <br>EMail: tst.advokat@gmail.com
                                <br>РЕЖИМ РАБОТЫ:
                                <br>Понедельник-пятница: 08.00 - 18.00
                                <br>Суббота, воскресенье: выходные дни.
                                <br>
                                В выходные дни возможен прием клиентов по предварительной записи по тел. 89023262392
                            </P>
                            <P class="text-white">
                                <br><b>Адреса дополнительных офисов:</b>
                                <br>125445, город Москва, ул. Смольная, д. 57, корпус 1, этаж 1, помещение XIV, комната 6
                                <br>424002, Республика Марий Эл, город Йошкар-Ола, бульвар Победы, дом 13
                            </P>
                        </div>
                    </div>
                </div>
                <div class="col-xl-5 offset-xl-1 col-md-6 p-r-0 map">
                    <div class="iframe-container">
                        <iframe src="https://www.google.com/maps/embed/v1/place?q=%D0%B3.%20%D0%9C%D0%BE%D1%81%D0%BA%D0%B2%D0%B0%2C%20%D1%83%D0%BB%D0%B8%D1%86%D0%B0%20%D0%92%D0%B8%D0%BA%D1%82%D0%BE%D1%80%D0%B5%D0%BD%D0%BA%D0%BE%2C%20%D0%B4.%2011&key=AIzaSyDKMnTqDKbqdJMTqM0AbV_n3Rh09WwadZ0"
                        ></iframe>
                    </div>
                </div>
                <div class="col-xl-5 p-l-0 col-md-6 set-z-index form-footer">
                    <div class="bg-white">
                        <div *ngIf="done" class="m-t-40 text-center">
                            <div class="form-group">
                                <br>
                                <h3>Спасибо за обращение!</h3>
                                <br>
                                <h4>В ближайшее время ожидайте звонок</h4>
                                <br>
                            </div>
                        </div>
                        <form #contForm="ngForm" novalidate (ngSubmit)="onSubmit(user)" *ngIf="!done">
                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label for="fio">Имя *</label>
                                        <input type="text" class="form-control" id="fio" name="fio" [(ngModel)]="user.name">
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label for="Phone">Телефон *</label>
                                        <input type="text" class="form-control" id="Phone" name="phone" [(ngModel)]="user.phone">
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label for="Email">Email *</label>
                                        <input type="text" class="form-control" id="Email" name="email" [(ngModel)]="user.email">
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label for="Subject">Тема *</label>
                                        <input type="text" class="form-control" id="Subject" name="subject" [(ngModel)]="user.subject">
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="Message">Сообщение *</label>
                                <input type="text" class="form-control" id="Message" name="message" [(ngModel)]="user.message">
                            </div>
                            <div class="row">
                                <div class="col-md-6 text-danger" *ngIf="ErrorSend">
                                    {{ErrorMessage}}
                                </div>
                                <div class="col-md-6">
                                    <button type="submit" class="btn btn-default primary-btn m-0-auto event-btn ">Отправить</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <img src="assets/images/event/footer/2.png" alt="" class="set-abs top-0 plane2">
        <img src="assets/images/event/footer/1.png" alt="" class="set-abs bottom-0 plane">
    </section>
    <app-footer></app-footer>
</div>


