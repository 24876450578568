import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-recall',
  templateUrl: './recall.component.html',
  styleUrls: ['./recall.component.scss']
})
export class RecallComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
