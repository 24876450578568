<div class="agency">
	<app-header style=""></app-header>
	<app-breadcrumb></app-breadcrumb>
	<section id="faq" class="saas1 faq testimonial-bg">
		<div class="container">
			<div class="row">
				<div class="col-md-8">
					<div class="faq-block">
						<div>
							<h3 class="frequent-text">Спектр юридических услуг</h3>
							<h6>Квалифицированная юридическая помощь адвоката физическим и юридическим лицам.</h6>
							<ngb-accordion [closeOthers]="true" class="accordion faq" activeIds="static-1">
								<ngb-panel id="static-1" class="card">
									<ng-template ngbPanelTitle class="card-header">
										<div class="collapsed" data-toggle="collapse" data-target="#collapse1"
											 aria-expanded="false">
											<a>
												<div class="fa fa-angle-right rotate"></div>
											</a>
											Услуги для граждан и юридических лиц
										</div>
									</ng-template>
									<ng-template ngbPanelContent class="card-body">
										<ul>
											<li><i class="fa fa-check"></i> консультирование</li>
											<li><i class="fa fa-check"></i> составление письменных жалоб, заявлений и
												прочих правовых документов
											</li>
											<li><i class="fa fa-check"></i> правовая экспертиза документов (договоров,
												соглашений и т.п.)
											</li>
											<li><i class="fa fa-check"></i> составление проектов договоров, контрактов,
												соглашений
											</li>
											<li><i class="fa fa-check"></i> сопровождение сделок, в том числе с
												недвижимостью
											</li>
											<li><i class="fa fa-check"></i> правовое обслуживание юридических лиц по
												договору
											</li>
										</ul>
									</ng-template>
								</ngb-panel>
								<ngb-panel id="static-2" class="card">
									<ng-template ngbPanelTitle class="card-header">
										<div class="collapsed" data-toggle="collapse" data-target="#collapse1"
											 aria-expanded="false">
											<a>
												<div class="fa fa-angle-right rotate"></div>
											</a>
											По административным делам
										</div>
									</ng-template>
									<ng-template ngbPanelContent class="card-body">
										<ul>
											<li><i class="fa fa-check"></i> представительство в административных органах
											</li>
											<li><i class="fa fa-check"></i> представительство в суде</li>
											<li><i class="fa fa-check"></i> составление жалоб</li>
											<li><i class="fa fa-check"></i> защита прав потерпевших</li>
										</ul>
									</ng-template>
								</ngb-panel>
								<ngb-panel id="static-3" class="card">
									<ng-template ngbPanelTitle class="card-header">
										<div class="collapsed" data-toggle="collapse" data-target="#collapse1"
											 aria-expanded="false">
											<a>
												<div class="fa fa-angle-right rotate"></div>
											</a>
											По гражданским делам
										</div>
									</ng-template>

									<ng-template ngbPanelContent class="card-body">
										<ul>
											<li><i class="fa fa-check"></i> составление исковых заявлений,
												апелляционной, кассационной и надзорной жалоб, претензий, иных
												документов
											</li>
											<li><i class="fa fa-check"></i> участие в суде первой, апелляционной,
												кассационной инстанций
											</li>
											<li><i class="fa fa-check"></i> внесудебная работа на выезде по поручению
												клиента
											</li>
											<li><i class="fa fa-check"></i> участие в исполнительном производстве</li>
										</ul>
									</ng-template>
								</ngb-panel>
								<ngb-panel id="static-4" class="card">
									<ng-template ngbPanelTitle class="card-header">
										<div class="collapsed" data-toggle="collapse" data-target="#collapse1"
											 aria-expanded="false">
											<a>
												<div class="fa fa-angle-right rotate"></div>
											</a>
											По уголовным делам
										</div>
									</ng-template>

									<ng-template ngbPanelContent class="card-body">
										<ul>
											<li><i class="fa fa-check"></i> участие на предварительном следствии</li>
											<li><i class="fa fa-check"></i> участие в суде первой, апелляционной,
												кассационной инстанций, надзорном производстве
											</li>
											<li><i class="fa fa-check"></i> составление апелляционных, кассационных,
												надзорных жалоб, ходатайств, заявлений в порядке частного обвинения,
												жалоб на действия сотрудников правоохранительных органов и т.п.
											</li>
											<li><i class="fa fa-check"></i> защита прав потерпевших на всех стадиях
												уголовного судопроизводства
											</li>
											<li><i class="fa fa-check"></i> представление интересов осужденных при
												отбытии ими уголовного наказания, условно-досрочном освобождении от
												отбывания наказания, применении акта амнистии, при досрочном погашении
												судимости
											</li>
											<li><i class="fa fa-check"></i> юридическая помощь при допросе лица в
												качестве свидетеля
											</li>
										</ul>
									</ng-template>
								</ngb-panel>
								<ngb-panel id="static-5" class="card">
									<ng-template ngbPanelTitle class="card-header">
										<div class="collapsed" data-toggle="collapse" data-target="#collapse1"
											 aria-expanded="false">
											<a>
												<div class="fa fa-angle-right rotate"></div>
											</a>
											По арбитражным делам
										</div>
									</ng-template>

									<ng-template ngbPanelContent class="card-body">
										<ul>
											<li><i class="fa fa-check"></i> составлений претензий, исковых заявлений
												(отзывов на исковые заявления), апелляционной, кассационной и надзорной
												жалоб, ходатайств
											</li>
											<li><i class="fa fa-check"></i> участие в суде первой, апелляционной,
												кассационной инстанций
											</li>
											<li><i class="fa fa-check"></i> участие в исполнительном производстве</li>
											<li><i class="fa fa-check"></i> внесудебная работа на выезде по поручению
												клиента
											</li>
										</ul>
									</ng-template>
								</ngb-panel>

								<ngb-panel id="static-7" class="card">
									<ng-template ngbPanelTitle class="card-header">
										<div class="collapsed" data-toggle="collapse" data-target="#collapse1"
											 aria-expanded="false">
											<a>
												<div class="fa fa-angle-right rotate"></div>
											</a>
											СПЕЦИАЛИЗАЦИЯ
										</div>
									</ng-template>
									<ng-template ngbPanelContent class="card-body">
										<ul>
											<li><i class="fa fa-check"></i> жилищные споры (о разделе жилого дома в
												натуре, об определении долей в праве собственности, о признании
												утратившим, прекратившим право пользования жилым помещением, выселении,
												снятии с регистрационного учета, в связи с затоплением квартиры);
											</li>
											<li><i class="fa fa-check"></i> споры по делам, вытекающим из
												брачно-семейных отношений (об определении порядка общения с ребенком, о
												возврате ребенка, об определении места жительства ребенка при раздельном
												проживании родителей, о лишении родительских прав, о признании брачного
												договора недействительным, о расторжении брака и разделе имущества, об
												установлении и оспаривании отцовства, взыскании и т.п.);
											</li>
											<li><i class="fa fa-check"></i> земельные споры;</li>
											<li><i class="fa fa-check"></i> трудовые споры (о восстановлении и на работе
												и оплате за время вынужденного прогула, о снятии дисциплинарного
												взыскания, о взыскании заработной платы, о возмещении ущерба,
												причиненного работником при исполнении трудовых обязанностей и др.);
											</li>
											<li><i class="fa fa-check"></i> дела особого производства;</li>
											<li><i class="fa fa-check"></i> наследственные дела (признание права
												собственности в порядке наследования, установление факта принятия
												наследства, оформление наследства и др.);
											</li>
											<li><i class="fa fa-check"></i> споры по возмещению вреда, причиненного
												жизни и здоровью, имуществу, компенсации морального вреда;
											</li>
											<li><i class="fa fa-check"></i> пенсионные споры;</li>
											<li><i class="fa fa-check"></i> споры из договоров страхования;</li>
											<li><i class="fa fa-check"></i> споры по делам, возникающих из
												правоотношений по защите прав потребителей;
											</li>
											<li><i class="fa fa-check"></i> споры по вопросам налогообложения;</li>
											<li><i class="fa fa-check"></i> споры по исполнению обязательств по
												договорам (возврат задолженности);
											</li>
											<li><i class="fa fa-check"></i> истребование имущества из чужого незаконного
												владения и др.
											</li>
										</ul>
									</ng-template>
								</ngb-panel>
							</ngb-accordion>
							<h6 class="link">Есть вопросы? Напишите мне: <a>info@tstadvokat.ru</a></h6>
						</div>
					</div>
				</div>
				<div class="col-md-4">
					<div class="faq-img-block">
						<img src="assets/images/themis.png" class="img-fluid" alt="">
					</div>
				</div>
			</div>
		</div>
	</section>
	<app-footer></app-footer>
</div>
